$themeTopPadding: 0.571rem;
$themeSidePadding: 0.857rem;
$themePadding: $themeTopPadding $themeSidePadding;
$halfSidePadding: 0.45285rem; // Half of theme side padding: 0.857rem.
$minimalPadding: 0.1rem;
$primaryColor: #06a4f7;
$primaryPadding: 0.4em;
$primaryColorDark: darken($primaryColor, 10%);
$primaryColorLight: lighten($primaryColor, 40%);
$primaryColorSemiLight: lighten($primaryColor, 20%);
$primaryBoxShadow: 0 0 0 0.2em $primaryColorLight;