@import "variables";

body {
  &,
  .p-component {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem;
  }

  .center-content {
    text-align: center;
  }

  h1 {
    font-size: 1.6rem;
    line-height: 0;
    color: $primaryColor;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: normal;
    line-height: 0;
    color: $primaryColor;
  }

  h3 {
    font-size: 1rem;
    line-height: 0;
    margin: 0.7em 0;
  }

  p-button.no-pointer {
    pointer-events: none;
  }

  .p-button {
    background: $primaryColor;
    border: 1px solid $primaryColor;
    border-radius: 1px;
    padding: $primaryPadding;

    &:enabled {
      &:hover,
      &:active {
        background-color: $primaryColorDark;
        border-color: $primaryColorDark;
      }

      &:focus {
        box-shadow: $primaryBoxShadow;
      }
    }
  }

  .p-listbox {
    width: auto;
    border-radius: 1px;

    .p-listbox-list .p-listbox-item {
      padding: $primaryPadding;

      &.p-highlight {
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
      }

      &:focus {
        box-shadow: inset $primaryBoxShadow;
      }
    }
  }

  .p-datatable {
    table {
      table-layout: auto;

      .p-datatable-thead,
      .p-datatable-tbody,
      .p-datatable-tfoot {
        > tr {
          &.divider {
            height: 1em;
          }

          &.sub-header > {
            th,
            td {
              background: lightgrey;
              font-weight: normal;
            }
          }

          &.sub-sub-header {
            &.dummy-row {
              display: none;
            }

            > {
              th,
              td {
                background: gainsboro;
                font-weight: normal;
              }
            }
          }

          > th,
          > td {
            border: none;

            &.row-name-holder {
              overflow: hidden;
              text-overflow: ellipsis;

              &.medium-width-holder {
                width: 20em;
              }
            }

            &.quarter-line-name-holder {
              padding-left: 3em;
            }

            &.amount-holder,
            &.number-holder {
              text-align: right;
            }

            &.amount-holder {
              width: 7em;
            }

            &.number-holder {
              width: 5em;
            }

            &.item-id-holder {
              width: 6em;
            }

            &.icon-button-holder {
              white-space: nowrap;

              & {
                padding: 0 0 0 1.4em;
                width: 6em;
              }

              &.single-icon {
                width: 2.3em;
                padding: 0;
              }

              .p-button {
                height: 2.3em;
                width: 2.3em;

                &.no-pointer {
                  pointer-events: none;
                }

                &:enabled:focus {
                  box-shadow: none;
                }
              }
            }

            &.input-cell {
              padding: $minimalPadding $halfSidePadding;

              .p-dropdown,
              .p-inputtext {
                width: 100%;

                &:not(.p-disabled).p-focus {
                  box-shadow: none;
                }
              }

              input {
                width: 6em;

                &:enabled:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
      }

      .p-datatable-thead > tr:not(.sub-header) > th {
        text-align: center;
        background: $primaryColor;
        color: white;

        &.icon-button-holder .p-button {
          background: $primaryColor;
          border: $primaryColor;

          &:enabled:hover {
            background-color: $primaryColorDark;
            border: $primaryColorDark;
          }
        }
      }

      .p-datatable-tbody,
      .p-datatable-tfoot {
        > tr {
          &.sub-section {
            &,
            & > td {
              background: $primaryColorLight;
            }

            & > td.blocked {
              font-style: italic;
              color: black;
              opacity: 0.5;
            }
          }

          > td {
            &.icon-button-holder .p-button {
              background: $primaryColorLight;
              border: $primaryColorLight;

              &:enabled:hover {
                background-color: $primaryColorSemiLight;
                border: $primaryColorSemiLight;
              }

              &,
              &:enabled:hover {
                color: black;
              }
            }
          }
        }
      }
    }

    &.p-datatable-scrollable table {
      table-layout: fixed;

      .p-datatable-thead,
      .p-datatable-tbody,
      .p-datatable-tfoot {
        > tr {
          &.fixed-width-setter {
            height: 0;

            td,
            th {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .p-tooltip {
    max-width: 20em;

    &.p-tooltip-top .p-tooltip-arrow {
      border-right-color: $primaryColorSemiLight;
    }

    &.p-tooltip-right .p-tooltip-arrow {
      border-right-color: $primaryColorSemiLight;
    }

    &.p-tooltip-bottom .p-tooltip-arrow {
      border-right-color: $primaryColorSemiLight;
    }

    &.p-tooltip-left .p-tooltip-arrow {
      border-right-color: $primaryColorSemiLight;
    }

    .p-tooltip-text {
      background: $primaryColorSemiLight;
      color: black;
    }
  }
}
